@tailwind base;
@tailwind components;
@tailwind utilities;

.dynamic-height {
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto; /* To allow scrolling within the element if needed */
}

@layer utilities {
  .custom-scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #e5e5e5;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #e5e5e5;
  }
}

.bot-text p{
font-size: 14px;
line-height: 22px;
}

.bot-text p strong{

  font-size: 16px;
  


}
.bot-text p:first-of-type{
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 15px;
  margin-top: 5px;
  
}
.bot-text > ul{
   margin-bottom: 15px;
}
.bot-text ul li{
 font-size: 15px;
 line-height: 22px;
}

.bot-text ul ul li{
  font-size: 15px;
  line-height: 22px;
}
